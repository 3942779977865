$background-color: #0A0E13;
$header-height: 80px;
$header-mobile-height: 60px;
$header-logo-height: 50px;
$header-logo-mobile-height: 40px;
$chat-input-height: 70px;
$chat-input-mobile-height: 60px;
$chat-input-container-height: 115px;
$chat-input-container-mobile-height: 90px;
$chat-input-background-color: lighten($background-color, 5%);
$chat-input-color: lighten($background-color, 90%);
$chat-input-placeholder-color: lighten($background-color, 80%);
$chat-input-suggestions-height: 40px;
$introDelay: 0.4s;

$headerMaxWidth: 100%;
$threadMaxWidth: 100%;
$inputMaxWidth: 1000px;

$dot-color: #fff;
$dot-size: 6px;
$dot-spacing: 3px;
$animation-duration: 1s;

$purple: #8884d8;
$pink: #ff1f9d;
$green: #75ffd6;

.color-purple {
  color: $purple !important;
}
.color-pink {
  color: $pink !important;
}
.color-green {
  color: $green !important;
}

$breakpoints: (
  'sm': 767px,
  'md': 992px,
  'lg': 1200px
) !default;

@mixin respond-to ($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  @else {
    @warn "No value found for `#{$breakpoint}`.";
  }
}