.chat-thread-container {
    position: fixed;

    top: $header-mobile-height;
    bottom: $chat-input-container-mobile-height;
    @include respond-to('sm') {
        top: $header-height;
        bottom: $chat-input-container-height;
    }

    width: 100%;

    .chat-thread {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;

        max-width: $threadMaxWidth;
        margin: auto;

        &:before {
            background: linear-gradient(180deg,$background-color 0,rgba(10,14,19,0));
            content: "";
            height: 50px;
            left: 0;
            pointer-events: none;
            position: fixed;
            width: 100%;
            z-index: 2;
            top: $header-mobile-height;
            @include respond-to('sm') {
                top: $header-height;
            }
        }

        &:after {
            background: linear-gradient(0deg,$background-color 0,rgba(10,14,19,0));
            content: "";
            height: 50px;
            left: 0;
            pointer-events: none;
            position: fixed;
            width: 100%;
            z-index: 2;
            bottom: $chat-input-container-mobile-height;
            @include respond-to('sm') {
                bottom: $chat-input-container-height;
            }
        }

        button[class^="react-scroll-to-bottom"] {
            left: 50%;
            right: auto;
            margin-left: -55px;
            transform: translateY(-50%);
            width: auto;
            height: auto;
            padding: 10px 0;
            border-radius: 30px;
            z-index: 5;
            background-color: lighten($background-color, 80%);
            color: darken($background-color, 90%);
            opacity: 0;
            user-select: none;
            font-size: 14px;
            width: 110px;
            animation: moveIn 0.5s ease forwards;
            
            &:after {
                content: 'Scroll Down';
            }
        }

        .chat-thread-scroll-view {
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            height: auto;
            max-height: 100%;
            padding-top: 45px;
            padding-bottom: 45px;

            @include respond-to(sm) {
                padding-left: 30px;
                padding-right: 30px;
            }

            align-items: flex-end;
            display: flex;
            flex-flow: column;
        }
            
        .chat-message {
            max-width: 92%;
            margin-bottom: 6px;

            box-sizing: border-box;
            font-size: 16px;
            line-height: 23px;
            // padding: 14px 18px;
            padding: 0 18px;

            // 14px faux padding using pseudo element
            &:before, &:after {
                content: '';
                height: 14px;
                display: block;
            }

            .typing-message {
                height: 55px;
            }
            
            .pre-chart {
                padding-bottom: 4px;
            }
            .post-chart {
                padding-top: 4px;
            }

            &.graph {
                width: 100%;
                .recharts-tooltip-wrapper {
                    outline: 0;
                    .chart-tooltip {
                        padding: 10px 12px;
                        background-color: rgba(010, 014, 019, 0.9);
                        border-radius: 4px;
                        p {
                            margin: 0;
                            font-size: 14px;
                            line-height: 21px;
                            color: lighten($background-color, 90%);
                            &.payment {
                                font-weight: bold;
                                font-size: 1em;
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
                .recharts-legend-item-text {
                    text-transform: capitalize;
                }
                .graph-container {
                    width: 100%;
                    height: 190px;
                }
                .chart-container {
                    width: 100%;
                    height: 300px;
                } 
            }

            &.chart {
                width: 100%;
                .chart-container {
                    width: 100%;

                    $table-bg-color: #131a24;
                    $table-border-color: #2b3a4a;
                    $table-text-color: #ffffff;
                    $table-header-bg-color: #0f1620;
                    $table-row-hover-bg-color: #1c2733;

                    table {
                        background-color: $table-bg-color;
                        border-collapse: collapse;
                        width: 100%;
                        color: $table-text-color;
                        border: none;
                    }

                    tbody {
                        display: block;
                        overflow-y: auto;
                        padding: 15px 0;
                    }

                    thead,
                    tbody tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }

                    .tbody-wrapper {
                        position: relative;
                        overflow: hidden;
                        height: 300px;
                        @include respond-to(sm) {
                            height: 320px;
                        }
                    }
                    
                    .tbody-container {
                        height: 100%;
                        overflow-y: auto;
                        display: block;
                    }
                    
                    .shadow-overlay {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                        background-image: linear-gradient(to bottom, rgba($table-bg-color, 1) 0%, rgba($table-bg-color, 0) 15px), linear-gradient(to top, rgba($table-bg-color, 1) 0%, rgba($table-bg-color, 0) 15px);
                    }

                    th,
                    td {
                        padding: 10px;
                        text-align: left;
                    }

                    th {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 0.75em;
                        // padding-top: 0;
                        padding-bottom: 0;
                    }

                    tbody tr:nth-child(odd) {
                        background-color: darken($table-bg-color, 5%);
                    }
                }
            }

            @include respond-to(sm) {
                max-width: 70%;
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 27px;
                padding: 0 21px;
                // padding: 14px 21px;

                &.graph,
                &.chart {
                    max-width: 600px;
                    width: 100%;

                    .graph-container {
                        width: 100%;
                        height: 240px;
                    }
                    .chart-container {
                        width: 100%;
                        // height: 300px;
                    } 
                }
            }

            @include respond-to(md) {
                &.graph {
                    .graph-container {
                        width: 100%;
                        height: 250px;
                    }
                }
            }

            &:not(.no-animation) {
                will-change: opacity;
                opacity: 0;
                animation: chatMessageShow .6s cubic-bezier(.215,.61,.355,1) .2s forwards;
            }

            &.amorti {
                align-self: flex-start;
                background-color: lighten($background-color, 5%);
                color: lighten($background-color, 90%);

                border-radius: 0 24px 24px 24px; // Default or .chunk-single

                &.chunk-start {
                    border-radius: 24px 24px 24px 0;
                }
                &.chunk-inner {
                    border-radius: 0 24px 24px 0;
                }
                &.chunk-end {
                    border-radius: 0 24px 24px 24px;
                }
            }

            &.user {
                justify-content: flex-end;
                margin-left: auto;
                margin-right: 0;
                position: relative;
                margin-left: auto;
                margin-right: 0;
                background-color: lighten($background-color, 80%);
                color: darken($background-color, 90%);

                border-radius: 24px 0 24px 24px; // Default or .chunk-single

                &.chunk-start {
                    border-radius: 24px 24px 24px 0;
                }
                &.chunk-inner {
                    border-radius: 0 24px 24px 0;
                }
                &.chunk-end {
                    border-radius: 0 24px 24px 24px;
                }
                .chat-message-edit-save {
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #fff;
                    font-size: 14px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    cursor: pointer;
                    opacity: 0;
                    text-align: right;
                    padding-right: 10px;
                    box-sizing: border-box;
                    transition: opacity .375s cubic-bezier(.215,.61,.355,1);
                    transform: translateX(-100%);
                    white-space: nowrap;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &.show-edit-save {
                    .chat-message-edit-save {
                        opacity: 1;
                    }
                }
                &.is-editing {
                    .chat-message-edit-save {
                        opacity: 1;
                    }
                }
                .text-value {
                    outline: 0;
                    border: 0;
                    background-color: transparent;
                    appearance: none;
                    &::selection {
                        background-color: $purple;
                        color: #fff;
                    }
                    
                }
            }
        }
    }
}
.chat-input-container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: $background-color;
    box-sizing: border-box;

    height: $chat-input-container-mobile-height;
    padding: (( $chat-input-container-mobile-height - $chat-input-mobile-height ) / 2) 15px;
    @include respond-to('sm') {
        height: $chat-input-container-height;
        padding: 15px 30px 30px 30px;
    }
    
    .chat-input-text-container {
        max-width: $inputMaxWidth;
        margin: 0 auto;
        width: 100%;
        display: flex;
        // opacity: 0;
    }

    .chat-input {
        background-color: $chat-input-background-color;
        border: none;
        border-radius: 24px 0 0 24px;
        box-sizing: border-box;
        color: lighten($background-color, 90%);
        font-size: 18px;
        margin: 0;
        padding: 0 30px;
        flex: 1;
        height: $chat-input-mobile-height;
        line-height: $chat-input-mobile-height;
        @include respond-to('sm') {
            height: $chat-input-height;
            line-height: $chat-input-height;
        }

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $chat-input-placeholder-color;
        }
    }
    .chat-input-button {
        backface-visibility: hidden;
        background: none;
        border: none;
        border-radius: 0;
        cursor: pointer;
        overflow: hidden;
        will-change: background, border;
        border-radius: 0 24px 24px 0;

        height: $chat-input-mobile-height;
        width: $chat-input-mobile-height;
        @include respond-to('sm') {
            height: $chat-input-height;
            width: $chat-input-height;
        }

        transition: background-color 0.2s ease-in-out;
        background-color: $chat-input-background-color;
        color: $chat-input-color;

        &:hover {
            background-color: lighten($chat-input-background-color, 5%);
        }

        &:focus {
            background-color: lighten($chat-input-background-color, 5%);
            outline: none;
        }

        background-position: center;
        background-repeat: no-repeat;
        background-size: 64% auto;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='30' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.313 22v-9.888l4.537 4.538L22 15.5 15.5 9 9 15.5l1.15 1.15 4.537-4.538V22z' fill='%23CFD9E6' fill-rule='evenodd'/%3E%3C/svg%3E");
    }

    .chat-input-option-container {
        position: fixed;
        bottom: $chat-input-container-mobile-height;
        text-align: center;
        width: 100%;

        @include respond-to('sm') {
            bottom: $chat-input-container-height;
        }

        // opacity: 0;

        // Define the styles for the radio button wrapper
        .chat-input-option {
            display: inline-block;
            position: relative;

            &:not(:last-child) {
                margin-right: 8px;
                @include respond-to(sm) {
                    margin-right: 16px;
                }
            }
        }
        
        // Define the styles for the actual radio button
        .chat-input-option input[type="radio"] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }
        
        // Define the styles for the button label
        $suggestion-color: #000;
        $suggestion-background: #cfd9e6;

        .chat-input-option label {
            display: inline-block;
            background: $suggestion-background;
            border: 1px solid $suggestion-color;
            border: 0px;
            border-radius: 22px;
            outline: 0;
            color: $suggestion-color;
            cursor: pointer;
            font-size: 13px;
            line-height: 1;
            opacity: 0;
            overflow: hidden;
            transform: translateZ(0);
            transform-origin: 0 100%;
            user-select: none;
            will-change: opacity, background;
            padding: 10px 10px;
            opacity: 1;

            transition: background-color, color, box-shadow 0.2s ease-in-out;

            @include respond-to(sm) {
                border-width: 2px;
                font-size: 16px;
                padding: 12px 16px;
            }

            &:hover,
            &:focus {
                box-shadow: 0 0 0 3px $suggestion-background;
            }

            &:active {
                background-color: $suggestion-background;
                color: #0a0e13;
            }
        }
        
        // Define the styles for the button label when the radio button is checked
        .chat-input-option input[type="radio"]:checked + label {
            background-color: $suggestion-background;
            color: #0a0e13;
            box-shadow: 0 0 0 3px $suggestion-background;
        }

        .chat-input-option input[type="radio"]:focus + label {
            box-shadow: 0 0 0 3px $suggestion-background;
        }
    }

    &.input-type-text {
        .chat-input-text-container {
            animation: moveIn 0.5s ease forwards;
        }
    }
    &.input-type-option {
        .chat-input-option-container {
            animation: moveIn 0.5s ease forwards;
        }
    }
}

// Animations

@keyframes moveIn {
	0% {
	  transform: scale(0001,0001) translateY(60px);
	  opacity:0;
	}
	
	100% {
	  transform: scale(1,1) translateY(0px);
	  opacity:1;
	}
}

@keyframes chatMessageShow {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
}

* {
    // user-select none
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    tap-highlight-color: rgba(255, 255, 255, 0);
}

// Variables
$dot-color: #fff;
$dot-size: 6px;
$dot-spacing: 3px;
$animation-duration: 1s;

// Typing animation keyframes
@keyframes typing {
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-100%);
  }
}

// Typing dots style
.typing-dots {
  display: inline-flex;
  align-items: flex-end;

  span {
    display: inline-block;
    width: $dot-size;
    height: $dot-size;
    margin-right: $dot-spacing;
    background-color: $dot-color;
    border-radius: 50%;
    animation: typing $animation-duration infinite ease-in-out;

    &:nth-child(2) {
      animation-delay: $animation-duration / 3;
    }

    &:nth-child(3) {
      animation-delay: (2 * $animation-duration) / 3;
      margin-right: 0;
    }
  }
}