.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: scroll;

    .modal-dialog {
        position: relative;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        background-color: #131a24;
        border-radius: 20px;
        padding: 25px 30px;
        box-sizing: border-box;
        color: #ffffff;
        margin-top: 58px;
        margin-bottom: 58px;

        @include respond-to(sm) {
            margin-top: 100px;
        }

        &.large {
            max-width: 800px;
        }

        .close-icon {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 15px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            border: 2px solid rgba(255, 255, 255, 0.2);
            transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

            &:hover {
                border-color: #ffffff;
            }
        }

        p {
            margin-top: 20px;
        }

        em {
            display: block;
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            line-height: 20px;
        }

        li {
            &.new-badge,
            &.coming-soon-badge {
                &:after {
                    display: inline-block;
                    color: #ffffff;
                    font-size: 10px;
                    font-weight: 600;
                    padding: 0px 5px;
                    border-radius: 3px;
                    margin-left: 8px;
                    transform: translateY(-2px);
                }
            }
            &.new-badge {
                &:after {
                    content: 'NEW';
                    background-color: $pink;
                }
            }
            &.coming-soon-badge {
                &:after {
                    content: 'COMING SOON';
                    background-color: $purple;
                }
            }
        }

        .button-container {
            margin-top: 30px;
        }

        .button {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
